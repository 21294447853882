import React from "react";
import theme from "theme";
import { Theme, Image, Text, Link, Box, Strong, Span, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				dimple - Home
			</title>
			<meta name={"description"} content={"Een digitale werkplek, meer  overzicht en samenwerking in jouw zaak of organisatie.  Voor ondernemers, teams en freelancers.\n\n- Werk productief (samen) op kantoor, thuis en onderweg\n- Zie (opnieuw) de bomen door het bos\n- Rust in je hoofd(en dat van je team)   "} />
			<meta property={"og:title"} content={"Home"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam>
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="image" />
			<Override slot="linkBox" />
		</Components.MenuHam>
		<Section sm-padding="24px 0 0 0" background="--color-light" display="none">
			<Override slot="SectionContent" lg-height="1400p" />
			<Section padding="0px 0 0px 0" sm-padding="0px 0 0px 0" lg-padding="0px 0 0px 0">
				<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
				<Box
					padding="0px 160px 0px 0px"
					border-radius="24px"
					margin="0px 0px 0px 0px"
					width="60%"
					lg-padding="0px 50px 0px 0px"
					lg-width="50%"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					md-margin="0px 0px 60px 0px"
					md-align-items="center"
					lg-height="fit-content"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/undraw_team_collaboration_re_ow29%20%282%29.svg?v=2023-05-22T09:14:06.338Z"
						display="block"
						margin="20px 0px 60px 0px"
						max-width="100%"
						text-align="center"
					/>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Digitaal slimmer,
						<br />
						samen sterker{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--headline3" sm-text-align="center">
						beter digitaal samenwerken
						<br />
						voor ondernemers, teams en freelancers
					</Text>
					<Text margin="0px 0px 0px 0px" padding="20px 0px 0px 0px" font="heading4" />
					<Text
						margin="0px 0px 40px 0px"
						font="--base"
						color="--greyD3"
						md-text-align="center"
						md-margin="0px 0px 20px 0px"
					/>
					<Link
						href="mailto:dirk@dimple.be"
						text-decoration-line="initial"
						color="--light"
						font="--lead"
						hover-opacity="0.9"
						background="#04adb5"
						border-radius="8px"
						padding="11px 24px 12px 24px"
					>
						Maak een afspraak
					</Link>
				</Box>
				<Box
					display="flex"
					lg-flex-wrap="wrap"
					width="40%"
					flex-direction="column"
					lg-width="50%"
					md-width="100%"
				>
					<Box
						width="100%"
						display="flex"
						flex-direction="row"
						margin="0px 0px 40px 0px"
						lg-margin="0px 4% 15px 0px"
						sm-width="100%"
						sm-margin="0px 0px 30px 0px"
					>
						<Box>
							<Text
								margin="0px 0px 18px 0px"
								color="--darkL2"
								font="--headline3"
								lg-text-align="left"
								text-align="left"
							>
								Digitaal overwhelmed?
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--greyD3"
								font="normal 400 20px/1.3 --fontFamily-sans"
								lg-text-align="left"
								text-align="left"
								position="static"
							>
								{" "}• Loop je stilaan verloren in een{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									overvloed{" "}
								</Strong>
								aan mails, chat-berichten en documentfolders?{"  "}
								<br />
								• Raakt informatie{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									versnipperd{" "}
								</Strong>
								over vele applicaties en databases?{"  "}
								<br />
								• Besteed je meer tijd aan{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									zoeken{" "}
								</Strong>
								dan aan vinden?{" "}
								<br />
								• Hoe blijf je op elkaar afgestemd als je{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									plaats
								</Strong>
								- en{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									tijdsonafhankelijk{" "}
								</Strong>
								werkt?{"   "}
								<br />
								• Bieden excel sheets en todo-lijstjes toch niet het{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									overzicht{" "}
								</Strong>
								dat je zoekt?{"    "}
							</Text>
						</Box>
					</Box>
					<Box
						width="100%"
						display="flex"
						flex-direction="row"
						margin="0px 0px 40px 0px"
						lg-margin="0px 4% 15px 0px"
						sm-width="100%"
						sm-margin="0px 0px 30px 0px"
					>
						<Box>
							<Text
								margin="0px 0px 18px 0px"
								color="--darkL2"
								font="--headline3"
								lg-text-align="left"
								text-align="left"
							>
								Wij helpen jou (en je team) om
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--greyD3"
								font="normal 400 20px/1.3 --fontFamily-sans"
								lg-text-align="left"
								text-align="left"
							>
								{" "}• te kijken hoe en welke{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									informatie{" "}
								</Strong>
								stroomt (of niet) in jouw organisatie
								<br />
								• (samen)werkprocessen te{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									vereenvoudigen
								</Strong>
								<br />
								• te zorgen dat ieder opnieuw{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									overzicht{" "}
								</Strong>
								krijgt van alles wat voor hem of haar toe doet
								<br />
								• een{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									digitale werkomgeving
								</Strong>
								{" "}te ontwerpen en creëren 
op{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									maat
								</Strong>
								{" "}van jouw context en (groei)ambities
								<br />
								• een context te helpen bieden voor meer{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									productiviteit
								</Strong>
								,{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									creativiteit{" "}
								</Strong>
								en{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									werkplezier
								</Strong>
								<br />
								• te zorgen dat je efficiënt informatie kan uitwisselen met jouw{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									klanten{" "}
								</Strong>
								en{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									partners
								</Strong>
								<br />
								• je werk te verlichten door slimme{" "}
								<Span
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									<Strong
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										automatisering
									</Strong>
									<br />
									<Strong
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										•{" "}
									</Strong>
									je te begeleiden
								</Span>
								<Span
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									<br />
								</Span>
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px" />
			</Section>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" justify-content="center" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					color="--dark"
					sm-font="--headline1"
					text-align="center"
				>
					Digitaal slimmer,{"  "}
					<br />
					samen sterker
				</Text>
				<Text
					margin="20px 0px 0px 0px"
					font="--headline2"
					color="--primary"
					sm-font="--headline2"
					text-align="center"
				>
					beter digitaal samenwerken
					<br />
					voor ondernemers,{" "}
					<br />
					teams en freelancers
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline3"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					padding="50p 0px 0px 0px"
				/>
				<Image
					src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					align-items="center"
					justify-items="center"
					align-content="center"
					justify-content="center"
					width="1000px"
					align-self="auto"
					flex="0 1 auto"
					margin="40px 0px 0px 0px"
					sm-width="auto"
					sm-max-width="100%"
					srcSet="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section sm-padding="24px 0 0 0" background="--color-light" display="none">
			<Override slot="SectionContent" lg-height="1400p" />
			<Section padding="0px 0 0px 0" sm-padding="0px 0 0px 0" lg-padding="0px 0 0px 0">
				<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
				<Box
					padding="0px 160px 0px 0px"
					border-radius="24px"
					margin="0px 0px 0px 0px"
					width="60%"
					lg-padding="0px 50px 0px 0px"
					lg-width="50%"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					md-margin="0px 0px 60px 0px"
					md-align-items="center"
					lg-height="fit-content"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/undraw_team_collaboration_re_ow29%20%282%29.svg?v=2023-05-22T09:14:06.338Z"
						display="block"
						margin="20px 0px 60px 0px"
						max-width="100%"
						text-align="center"
					/>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Een digitale werkplek, meer overzicht en samenwerking in jouw zaak of organisatie
					</Text>
					<Text margin="0px 0px 0px 0px" font="--headline3" sm-text-align="center">
						voor ondernemers, teams en freelancers
					</Text>
					<Text margin="0px 0px 0px 0px" padding="20px 0px 0px 0px" font="heading4" />
					<Text
						margin="0px 0px 40px 0px"
						font="--base"
						color="--greyD3"
						md-text-align="center"
						md-margin="0px 0px 20px 0px"
					/>
					<Link
						href="mailto:dirk@dimple.be"
						text-decoration-line="initial"
						color="--light"
						font="--lead"
						hover-opacity="0.9"
						background="#04adb5"
						border-radius="8px"
						padding="11px 24px 12px 24px"
					>
						Maak een afspraak
					</Link>
				</Box>
				<Box
					display="flex"
					lg-flex-wrap="wrap"
					width="40%"
					flex-direction="column"
					lg-width="50%"
					md-width="100%"
				>
					<Box
						width="100%"
						display="flex"
						flex-direction="row"
						margin="0px 0px 40px 0px"
						lg-margin="0px 4% 15px 0px"
						sm-width="100%"
						sm-margin="0px 0px 30px 0px"
					>
						<Box>
							<Text
								margin="0px 0px 18px 0px"
								color="--darkL2"
								font="--headline3"
								lg-text-align="left"
								text-align="left"
							>
								Werk productief (samen), op kantoor, thuis en onderweg
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--greyD3"
								font="--base"
								lg-text-align="left"
								text-align="left"
							>
								Hoe zorg je dat{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									ideeën, cruciale informatie en kennis
								</Strong>
								{" "}niet verloren gaan?   Dat jij of je team er op elk moment mee aan de slag kan?  Ook als je niet op dezelfde tijd en plaats werkt?  Organiseer je werk met één handige digitale werkplek op maat.  Zo blijf je{" "}
								<Span
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									<Strong
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										altijd op elkaar afgestemd
									</Strong>
									<Span
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										,
									</Span>
									<Strong
										overflow-wrap="normal"
										word-break="normal"
										white-space="normal"
										text-indent="0"
										text-overflow="clip"
										hyphens="manual"
										user-select="auto"
										pointer-events="auto"
									>
										{" "}
									</Strong>
									op kantoor, onderweg of thuis
								</Span>
								.{"  "}
							</Text>
						</Box>
					</Box>
					<Box
						width="100%"
						display="flex"
						flex-direction="row"
						margin="0px 0px 40px 0px"
						lg-margin="0px 4% 15px 0px"
						sm-width="100%"
						sm-margin="0px 0px 30px 0px"
					>
						<Box>
							<Text
								margin="0px 0px 18px 0px"
								color="--darkL2"
								font="--headline3"
								lg-text-align="left"
								text-align="left"
							>
								Zie (opnieuw) de bomen door het bos
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--greyD3"
								font="--base"
								lg-text-align="left"
								text-align="left"
							>
								Loop je stilaan verloren in een overvloed aan mails, chat-berichten en documentfolders?  Raakt essentiële informatie versnipperd over meerdere applicaties en databases?  Besteed je meer tijd aan zoeken dan aan vinden?  Op een digitale werkplek heb je een makkelijk{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									overzicht van alles wat er toe doet
								</Strong>
								: projecten, doelen, to-do's, planning, klanten, documenten, offertes, facturen, dossiers, tools, bronnen, ideeën, ...{"          "}
							</Text>
						</Box>
					</Box>
					<Box
						width="100%"
						display="flex"
						flex-direction="row"
						margin="0px 0px 0px 0px"
						lg-margin="0px 4% 15px 0px"
						sm-width="100%"
						sm-margin="0px 0px 0px 0px"
					>
						<Box>
							<Text
								margin="0px 0px 18px 0px"
								color="--darkL2"
								font="--headline3"
								lg-text-align="left"
								text-align="left"
							>
								Rust in je hoofd (en dat van je team)
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--greyD3"
								font="--base"
								lg-text-align="left"
								text-align="left"
							>
								Je probeert in je hoofd het overzicht te bewaren?  Je experimenteerde al met todo-lijstjes en allerlei apps?  Je merkt dat deze telkens maar een deeltje van je zorgen oplossen?   Eén all-in-one digitale werkplek brengt je een{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									rustige, overzichtelijke en veilige omgeving.{"  "}
								</Strong>
								Zo kan je jouw hoofd meer inzetten op wat écht nodig en van waarde is om jouw doelen te bereiken.{"    "}
							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px" />
			</Section>
		</Section>
		<Section padding="40px 0 40px 0">
			<Override slot="SectionContent" justify-content="center" sm-font="--lead" />
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				flex-direction="column"
				flex="0 0 auto"
				sm-font="--lead"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Digitaal overwhelmed?{" "}
				</Text>
				<Text
					margin="40px 0px 40px 0px"
					font="--lead"
					color="--dark"
					sm-font="--lead"
					text-align="center"
				>
					{" "}Loop je stilaan verloren in een{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						overvloed{" "}
					</Strong>
					aan mails, chat-berichten en documentfolders?{"  "}
					<br />
					Raakt informatie{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						versnipperd{" "}
					</Strong>
					over vele applicaties, files en databases?{"  "}
					<br />
					Besteed je meer tijd aan{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						zoeken{" "}
					</Strong>
					dan aan vinden?{" "}
					<br />
					Heb je het idee dat het warm water meermaals wordt uitgevonden?
					<br />
					Hoe blijf je goed op elkaar afgestemd als je{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						plaats
					</Strong>
					- en{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						tijdsonafhankelijk{" "}
					</Strong>
					werkt?{"   "}
					<br />
					Bieden excel sheets en todo-lijstjes toch niet het{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						overzicht{" "}
					</Strong>
					dat je zocht?
					<br />
					Zit cruciale{"  "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						kennis of informatie{" "}
					</Strong>
					nu vooral in je hoofd of inbox?{" "}
					<br />
					Raakt dat{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						hoofd{" "}
					</Strong>
					stilaan{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						overvol
					</Strong>
					?{"    "}
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline3"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0">
			<Override slot="SectionContent" justify-content="center" />
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				flex-direction="column"
				flex="0 0 auto"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Ik help jou (en je team) om
				</Text>
				<Text
					margin="40px 0px 40px 0px"
					font="--lead"
					color="--dark"
					sm-font="--lead"
					text-align="center"
				>
					Zicht te krijgen op hoe{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						waarde{" "}
					</Strong>
					wordt toegevoegd in jouw organisatie
					<br />
					{"  "}Na te gaan welke{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						informatie{" "}
					</Strong>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						hierbij{" "}
					</Span>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						noodzakelijk of wenselijk
					</Span>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{" "}
					</Strong>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						is
						<br />
					</Span>
					In kaart te brengen hoe deze vandaag{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						stroomt{" "}
					</Strong>
					en{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						beheerd{" "}
					</Strong>
					wordt
					<br />
					Te ontdekken waar het proces vandaag{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						hapert
					</Strong>
					<br />
					Ideeën te (helpen) genereren om{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						processen{" "}
					</Strong>
					te{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						vereenvoudigen
						<br />
					</Strong>
					De{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						behoeften{" "}
					</Strong>
					en{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						randvoorwaarden{" "}
					</Strong>
					voor digitale ondersteuning te bepalen
					<br />
					Dashboards te bouwen die opnieuw{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						overzicht{" "}
					</Strong>
					bieden
					<br />
					Een{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						digitale werkplek
					</Strong>
					{" "}op maat te ontwikkelen
					<br />
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						al dan niet
					</Span>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{" "}geïntegreerd{" "}
					</Strong>
					met nieuwe of bestaande tools
					<br />
					Efficiënter informatie uit te wisselen met{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						klanten{" "}
					</Strong>
					en{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						partners
					</Strong>
					<br />
					Werk te verlichten en tijd te besparen door gerichte{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						automatisering
						<br />
					</Strong>
					<br />
					Met als doel meer{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						productiviteit
					</Strong>
					,{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						creativiteit{" "}
					</Strong>
					en{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						werkplezier
					</Strong>
					<br />
					voor jezelf, in je team of organisatie.
					<br />
					{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline3"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0">
			<Override slot="SectionContent" justify-content="center" />
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				flex-direction="column"
				margin="0px 0px -20px 0px"
				flex="0 0 auto"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					... en dit alles met
				</Text>
				<Text
					margin="40px 0px 20px 0px"
					font="--lead"
					color="--dark"
					sm-font="--lead"
					text-align="center"
				>
					jarenlange{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						ervaring{" "}
					</Strong>
					in kleine en grote organisaties, vanuit
					<br />
					{" "}diverse rollen (van project manager, business analyst tot team coach)
					<br />
					een{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						open
					</Strong>
					,{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						analytische
					</Strong>
					, en{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						onafhankelijke{" "}
					</Strong>
					blik
					<br />
					{"\n"}de passie om
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{" "}in- en overzicht te zoeken in chaos{" "}
					</Strong>
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						expertise{" "}
					</Strong>
					van state-of-the-art
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{" "}digitale technologie
						<br />
						aandacht{" "}
					</Strong>
					voor het geheel en de details
					<br />
					zorgzame{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						project{" "}
					</Strong>
					begeleiding
					<br />
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						niet ophoudende
					</Span>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{" "}leerzin{" "}
						<br />
					</Strong>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<br />
					</Span>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline3"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				/>
				<Button href="/aanbod" type="link" text-decoration-line="initial" sm-text-align="center">
					Meer weten?
				</Button>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" justify-content="center" />
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				flex-direction="column"
				flex="0 0 auto"
				font="--base"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Ervaringen van enkele klanten{" "}
				</Text>
				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="center"
					color="--darkL2"
					lg-width="100%"
					width="60%"
					border-color="--color-primary"
				>
					"Onze samenwerking met Dimple en het Notion-platform heeft de afhandeling en het beheer van onze verschillende projecten echt vereenvoudigd"
					<br />
					<Link
						href="https://www.linkedin.com/in/joyjouret/"
						target="_blank"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						font="300 16px/24px "
						color="--primary"
						text-decoration-line="initial"
					>
						Joy Jouret
					</Link>
					, Smart Mind
					<br />
					<br />
					"We are super enthousiast about our collaboration with Dirk! He’s solution focussed, result driven and on top of that comes his super talent, being empathetic. This gives him the power to understand your needs and build a tailor made product! Many thanks Dirk!"
					<br />
					<Link
						href="https://www.linkedin.com/in/ellen-schouppe-356ba22/"
						target="_blank"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--primary"
						text-decoration-line="initial"
					>
						Ellen Schouppe
					</Link>
					, Smart Mind
					<br />
					<br />
					"De samenwerking met Dirk heb ik als grondig en constructief ervaren. Dirk was altijd zeer goed voorbereid en werkt zeer gestructureerd. Daarnaast dacht hij ook actief mee na en werden onze bedenkingen of suggesties altijd gehoord en aangepakt."
					<br />
					<Link
						href="https://www.linkedin.com/in/elisabeth-torfs-7aa74852/"
						target="_blank"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--primary"
						text-decoration-line="initial"
					>
						Elisabeth Torfs
					</Link>
					, Torfs
				</Text>
			</Box>
			<Box
				margin="60px 0px 0px 0px"
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				justify-content="center"
			>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/torfs-coupon-codes.png?v=2024-04-18T12:05:02.590Z"
						border-radius="16px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/torfs-coupon-codes.png?v=2024-04-18T12%3A05%3A02.590Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/torfs-coupon-codes.png?v=2024-04-18T12%3A05%3A02.590Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/torfs-coupon-codes.png?v=2024-04-18T12%3A05%3A02.590Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/torfs-coupon-codes.png?v=2024-04-18T12%3A05%3A02.590Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/torfs-coupon-codes.png?v=2024-04-18T12%3A05%3A02.590Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/torfs-coupon-codes.png?v=2024-04-18T12%3A05%3A02.590Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/torfs-coupon-codes.png?v=2024-04-18T12%3A05%3A02.590Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/smartmind.png?v=2024-04-18T12:05:13.755Z"
						border-radius="16px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/smartmind.png?v=2024-04-18T12%3A05%3A13.755Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/smartmind.png?v=2024-04-18T12%3A05%3A13.755Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/smartmind.png?v=2024-04-18T12%3A05%3A13.755Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/smartmind.png?v=2024-04-18T12%3A05%3A13.755Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/smartmind.png?v=2024-04-18T12%3A05%3A13.755Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/smartmind.png?v=2024-04-18T12%3A05%3A13.755Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/smartmind.png?v=2024-04-18T12%3A05%3A13.755Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					justify-content="flex-start"
					align-content="center"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/bouwxlence.png?v=2024-04-18T12:05:29.054Z"
						border-radius="16px"
						max-width="100%"
						width="160px"
						srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/bouwxlence.png?v=2024-04-18T12%3A05%3A29.054Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/bouwxlence.png?v=2024-04-18T12%3A05%3A29.054Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/bouwxlence.png?v=2024-04-18T12%3A05%3A29.054Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/bouwxlence.png?v=2024-04-18T12%3A05%3A29.054Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/bouwxlence.png?v=2024-04-18T12%3A05%3A29.054Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/bouwxlence.png?v=2024-04-18T12%3A05%3A29.054Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/bouwxlence.png?v=2024-04-18T12%3A05%3A29.054Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/linkedfarm.png?v=2024-04-18T12:05:42.119Z"
						border-radius="16px"
						max-width="100%"
						width="120px"
						srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/linkedfarm.png?v=2024-04-18T12%3A05%3A42.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/linkedfarm.png?v=2024-04-18T12%3A05%3A42.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/linkedfarm.png?v=2024-04-18T12%3A05%3A42.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/linkedfarm.png?v=2024-04-18T12%3A05%3A42.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/linkedfarm.png?v=2024-04-18T12%3A05%3A42.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/linkedfarm.png?v=2024-04-18T12%3A05%3A42.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/linkedfarm.png?v=2024-04-18T12%3A05%3A42.119Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/daaromwel.png?v=2024-04-18T12:06:14.259Z"
						border-radius="16px"
						max-width="100%"
						width="100px"
						srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/daaromwel.png?v=2024-04-18T12%3A06%3A14.259Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/daaromwel.png?v=2024-04-18T12%3A06%3A14.259Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/daaromwel.png?v=2024-04-18T12%3A06%3A14.259Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/daaromwel.png?v=2024-04-18T12%3A06%3A14.259Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/daaromwel.png?v=2024-04-18T12%3A06%3A14.259Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/daaromwel.png?v=2024-04-18T12%3A06%3A14.259Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/daaromwel.png?v=2024-04-18T12%3A06%3A14.259Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Image
					src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/I-coach-leaders-teams-to-thrive-in-chaos-Debbie-Baute-Belgium%20%281%29.png?v=2024-05-28T18:25:37.035Z"
					display="block"
					width="200px"
					srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/I-coach-leaders-teams-to-thrive-in-chaos-Debbie-Baute-Belgium%20%281%29.png?v=2024-05-28T18%3A25%3A37.035Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/I-coach-leaders-teams-to-thrive-in-chaos-Debbie-Baute-Belgium%20%281%29.png?v=2024-05-28T18%3A25%3A37.035Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/I-coach-leaders-teams-to-thrive-in-chaos-Debbie-Baute-Belgium%20%281%29.png?v=2024-05-28T18%3A25%3A37.035Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/I-coach-leaders-teams-to-thrive-in-chaos-Debbie-Baute-Belgium%20%281%29.png?v=2024-05-28T18%3A25%3A37.035Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/I-coach-leaders-teams-to-thrive-in-chaos-Debbie-Baute-Belgium%20%281%29.png?v=2024-05-28T18%3A25%3A37.035Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/I-coach-leaders-teams-to-thrive-in-chaos-Debbie-Baute-Belgium%20%281%29.png?v=2024-05-28T18%3A25%3A37.035Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/I-coach-leaders-teams-to-thrive-in-chaos-Debbie-Baute-Belgium%20%281%29.png?v=2024-05-28T18%3A25%3A37.035Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/logo_wooncoop.png?v=2024-06-20T12:52:26.777Z"
					display="block"
					width="200px"
					srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/logo_wooncoop.png?v=2024-06-20T12%3A52%3A26.777Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/logo_wooncoop.png?v=2024-06-20T12%3A52%3A26.777Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/logo_wooncoop.png?v=2024-06-20T12%3A52%3A26.777Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/logo_wooncoop.png?v=2024-06-20T12%3A52%3A26.777Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/logo_wooncoop.png?v=2024-06-20T12%3A52%3A26.777Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/logo_wooncoop.png?v=2024-06-20T12%3A52%3A26.777Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/logo_wooncoop.png?v=2024-06-20T12%3A52%3A26.777Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Link href="#" color="#000000" />
		<Components.Contact2>
			<Override slot="text1">
				dirk@dimple.be
				<br />
				+32 472 61 99 33
				<br />
				{"\n"}btw: BE 0870.059.613{"\n\n"}
				<br />
				{"\n"}iban: BE59 7340 1327 1326
				<br />
				{"\n"}bic: KREDBEBB{"\n\n\n\n"}
				<br />
				<Link
					href="https://www.linkedin.com/in/dirkdiddens/"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					LinkedIn
				</Link>
			</Override>
		</Components.Contact2>
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2025 dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});