import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Span, Box, Image, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aanpak1"} />
		<Helmet>
			<title>
				dimple - Aanpak
			</title>
			<meta name={"description"} content={"dimple gelooft in co-creatie, heldere afspraken en respectvolle, open communicatie als basis voor een duurzame en inspirerende samenwerking.\ndimple hanteert een incrementele en hands-on aanpak waarbij we werkende oplossingen als graadmeter voor (zichtbare) vooruitgang zien.  \ndimple omarmt de principes van design thinking, systeem denken en een agile mindset."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam />
		<Section padding="24px 0 40px 0">
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Tools
				</Text>
				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="center"
					color="--darkL2"
					lg-width="100%"
					width="60%"
				>
					De wereld van digitale tools evolueert razendsnel.  In deze overvloed is het een uitdaging om de juiste technologie in te zetten voor de juiste taak.  Dimple helpt u tot goede keuzes te komen.  Hierbij selecteren we uitsluitend applicaties en platformen  die{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						gebruiks
					</Strong>
					- en{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						budgetvriendelijk{" "}
					</Strong>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						zijn
					</Span>
					, deel uitmaken van een gezond en groeiend{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						ecosysteem{" "}
					</Strong>
					én die{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						flexibel
					</Strong>
					,{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						schaal
					</Strong>
					- en{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						onderhoudbaar{" "}
					</Strong>
					zijn.    Dimple heeft uitgebreide expertise en ervaring met onderstaande tools voor het realiseren van uw digitale oplossing.
					<br />
					{"\n\n\n\n"}
				</Text>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				flex-direction="row"
				flex-wrap="wrap"
				text-align="left"
				position="static"
				display="flex"
				align-self="auto"
				overflow-x="auto"
				align-items="stretch"
				justify-content="center"
			>
				<Box
					position="relative"
					display="block"
					flex-direction="column-reverse"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					align-items="center"
					justify-content="space-between"
					flex="0 0 auto"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/notion_logo.png?v=2023-05-30T19:28:42.477Z"
						border-radius="16px"
						max-width="100%"
						width="150px"
						margin="30px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/notion_logo.png?v=2023-05-30T19%3A28%3A42.477Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/notion_logo.png?v=2023-05-30T19%3A28%3A42.477Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/notion_logo.png?v=2023-05-30T19%3A28%3A42.477Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/notion_logo.png?v=2023-05-30T19%3A28%3A42.477Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/notion_logo.png?v=2023-05-30T19%3A28%3A42.477Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/notion_logo.png?v=2023-05-30T19%3A28%3A42.477Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/notion_logo.png?v=2023-05-30T19%3A28%3A42.477Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					align-content="stretch"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/tallylogo.png?v=2023-05-30T19:33:33.871Z"
						border-radius="16px"
						max-width="100%"
						margin="60px 0 0px 0px"
						width="120px"
						padding="0px 10px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/tallylogo.png?v=2023-05-30T19%3A33%3A33.871Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/tallylogo.png?v=2023-05-30T19%3A33%3A33.871Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/tallylogo.png?v=2023-05-30T19%3A33%3A33.871Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/tallylogo.png?v=2023-05-30T19%3A33%3A33.871Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/tallylogo.png?v=2023-05-30T19%3A33%3A33.871Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/tallylogo.png?v=2023-05-30T19%3A33%3A33.871Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/tallylogo.png?v=2023-05-30T19%3A33%3A33.871Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				margin="40px 0px 0px 0px"
				flex-direction="row"
				flex-wrap="wrap"
				text-align="left"
				position="static"
				align-self="auto"
				display="flex"
				overflow-x="auto"
				align-items="stretch"
				justify-content="center"
			>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/make.jpg?v=2023-05-26T20:39:44.796Z"
						border-radius="16px"
						max-width="100%"
						width="150px"
						margin="30px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/make.jpg?v=2023-05-26T20%3A39%3A44.796Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/make.jpg?v=2023-05-26T20%3A39%3A44.796Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/make.jpg?v=2023-05-26T20%3A39%3A44.796Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/make.jpg?v=2023-05-26T20%3A39%3A44.796Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/make.jpg?v=2023-05-26T20%3A39%3A44.796Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/make.jpg?v=2023-05-26T20%3A39%3A44.796Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/make.jpg?v=2023-05-26T20%3A39%3A44.796Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="10px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/52d3739c-1f4c-484e-8e03-8cd73d3b92c8.svg?v=2024-04-18T10:17:48.683Z"
						border-radius="16px"
						max-width="100%"
						width="120px"
						margin="20px 0px 0px 0px"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="0px 0px 0px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					align-content="stretch"
				>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/2560px-Airtable_Logo.svg.png?v=2024-04-18T11:14:12.258Z"
						border-radius="16px"
						max-width="100%"
						margin="30px 0 0px 0px"
						width="150px"
						padding="0px 10px 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Troeven sm-background="--color-primary">
			<Override slot="text7">
				Een digitale werkplek is géén doel op zich.   Het is{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					een middel
				</Strong>
				{" "}om mensen te helpen om hun doelen te bereiken. dimple heeft ruime ervaring met het ontwerp en de bouw van gebruikersvriendelijke digitale webapplicaties binnen een professionele context.
			</Override>
			<Override slot="text5">
				dimple ontwikkelt digitale werkplekken{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					afgestemd op jouw doelen, noden en werkcontext
				</Strong>
				{" "}(en niet omgekeerd!).   Een digitale werkplek is gemaakt om mee te groeien met jouw zaak.  Met elke nieuwe uitdaging en behoefte die zich aandient.{"        "}
			</Override>
		</Components.Troeven>
		<Components.Contact2 />
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2025 dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});