import React from "react";
import theme from "theme";
import { Theme, Text, Link, Strong, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aanpak"} />
		<Helmet>
			<title>
				dimple - Aanpak
			</title>
			<meta name={"description"} content={"dimple gelooft in co-creatie, heldere afspraken en respectvolle, open communicatie als basis voor een duurzame en inspirerende samenwerking.\ndimple hanteert een incrementele en hands-on aanpak waarbij we werkende oplossingen als graadmeter voor (zichtbare) vooruitgang zien.  \ndimple omarmt de principes van design thinking, systeem denken en een agile mindset."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam />
		<Section lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-2" color="--light" lg-color="--light">
			<Override slot="SectionContent" color="--light" />
			<Text margin="0px 0px 20px 0px" font="--headline2" color="--dark" text-align="center">
				Aanpak
			</Text>
			<Text
				color="--dark"
				font="--lead"
				lg-margin="0px 0 80px 0px"
				md-margin="0px 0 50px 0px"
				text-align="center"
				width="100% border-box"
				height="100% border-box"
				margin="16px 60px 16px 60px"
			>
				Wij omarmen de principes van
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Link
						href="https://www.youtube.com/watch?v=8pz4EYwmgrs"
						target="_blank"
						color="--primary"
						text-decoration-line="initial"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						co-creatie
					</Link>
					<br />
					{" "}
					<Link
						href="https://www.youtube.com/watch?v=_r0VX-aU_T8"
						target="_blank"
						link-color="--primary"
						link-text-decoration-line="initial"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--primary"
					>
						design thinking
					</Link>
					<br />
					<Link
						href="https://www.youtube.com/watch?v=Miy9uQcwo3U"
						target="_blank"
						color="--primary"
						text-decoration-line="initial"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						systems thinking
					</Link>
					<br />
					<Link
						href="https://www.youtube.com/watch?v=SqvB493oDVc"
						target="_blank"
						color="--primary"
						text-decoration-line="initial"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						agile werken
					</Link>
					<br />
				</Strong>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
				</Strong>
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="40px 30px"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap="26px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						align-items="center"
						display="flex"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px" font="--base">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--dark" font="normal 500 20px/1.2 --fontFamily-sans">
							Kennismaking
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
							Tijdens een eerste gesprek (1-2h, online of fysiek), maken we kennis met elkaar, jouw context, vraag en verwachtingen.  Op basis hiervan bakenen we in overleg een eerste werkpakket ("opdracht") af.  Dit werkpakket kan omvatten:{" "}
							<br />
							- creatie van een{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								prototype
							</Strong>
							, bv. om te illustreren hoe jouw werkplek er zou kunnen uitzien
							<br />
							- creatie van werkende{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								werkplek
							</Strong>
							, met een welomschreven set aan functionaliteiten
							<br />
							- creatie van een aantal voorafgesproken andere{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								artefacts{" "}
							</Strong>
							(bv. beschrijving van de gewenste toekomstige situatie, een uitgeschreven behoefteanalyse, ...)
							<br />
							- een aantal{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								overleg{" "}
							</Strong>
							momenten en{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								workshops
								<br />
							</Strong>
							- één of enkele specifieke{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								coaching of training{" "}
							</Strong>
							sessies
							<br />
							- een{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								combinatie{" "}
							</Strong>
							van dit alles
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px" font="--base">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--dark" font="normal 500 20px/1.2 --fontFamily-sans">
							Offerte
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--dark">
							- dimple maakt een offerte voor uitvoering van de opdracht.  Deze houdt rekening met de (geschatte) complexiteit, doorlooptijd, benodigd aantal workshops en tussentijds overleg.{"  "}
							<br />
							- dimple zal in zijn offerte ook een aantal{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								uitgangspunten{" "}
							</Strong>
							vermelden, noodzakelijk voor een vlotte uitvoering.{"  "}
							<br />
							- dimple werkt standaard met een{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								vaste prijs (en scope){" "}
							</Strong>
							offerte, zo weet je vooraf met welk budget rekening te houden.  Op vraag werken we ook in{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								regie{" "}
							</Strong>
							(obv gedetailleerde timesheet).
							<br />
							- dimple maakt vaste prijs/scope-offertes voor opdrachten die voldoende omlijnd zijn om realistisch te kunnen inschatten.   Zo nodig wordt de opdracht opgesplitst in kleinere afgebakende gehelen.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
					height={0}
					font="--base"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--dark" font="normal 500 20px/1.2 --fontFamily-sans">
							Akkoord offerte
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--dark">
							{" "}Van zodra je de offerte goedkeurt, plannen we de uitvoeringsfase.{" "}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
					height={0}
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							4
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px" font="--base">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--dark" font="normal 500 20px/1.2 --fontFamily-sans">
							Uitvoering
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--dark">
							- We delen de uitvoeringsfase op in een aantal "
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								iteraties
							</Strong>
							".  Bij voorkeur met een{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								vaste lengte
							</Strong>
							{" "}(bv. 1 of 2 weken).
							<br />
							- Na elke iteratie plannen we een{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								overleg{" "}
							</Strong>
							(1-2h, fysiek of online).  Die omvat steeds: een{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								demo{" "}
							</Strong>
							van de werkplek-in-wording, planning en{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								verfijning{" "}
							</Strong>
							van de volgende iteratie (workshop) en het  overlopen van de openstaande en in te plannen{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								acties
							</Strong>
							.
							<br />
							Zo creëren we een werkbaar ritme, mét voldoende ruimte voor tussentijdse feedback en bijsturing.{" "}
							<br />
							<br />
							Hou er rekening mee dat ook jij (voldoende) tijd inplant om de nieuwe omgeving te leren kennen, te testen, je vragen te verzamelen, content te creëren en - indien van toepassing -, je team te betrekken.{"  \n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="0px 0 0px 0"
			lg-padding="80px 0 90px 0"
			quarkly-title="Stages/Steps-2"
			color="--light"
			font="--base"
		>
			<Override
				slot="SectionContent"
				align-self="auto"
				color="--light"
				sm-display="flex"
				sm-align-self="auto"
				sm-flex="0 1 auto"
				sm-order="0"
				sm-flex-direction="column"
				sm-justify-content="flex-start"
				sm-flex-wrap="nowrap"
				sm-margin="200px 28.7969px 0px 28.7969px"
			/>
			<Box
				min-width="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="40px 30px"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap="26px"
				max-height="200px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
					margin="20px 0px 0px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						align-items="center"
						display="flex"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						background="--color-primary"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							5
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--dark" font="normal 500 20px/1.2 --fontFamily-sans">
							Oplevering en garantie
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--dark">
							Na de laatste iteratie volgt de "oplevering".  Dan start de garantie-periode.  Je kan je werkplek in gebruik nemen.  Tijdens de garantie worden eventuele mankementjes kosteloos verholpen.{"   \n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
					margin="20px 0px 0px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-primary"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							6
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--dark" font="normal 500 20px/1.2 --fontFamily-sans">
							Nazorg
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--dark">
							Na de garantie-periode kan je nog steeds beroep blijven doen op dimple.  Interventies, aanpassingen, ... worden in regie gefactureerd.{"  \n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section sm-padding="100px 0 24px 0">
			<Override slot="SectionContent" max-width="70%" sm-display="none" />
			<Image src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple%20logo%20-%20Page%203.svg?v=2023-05-25T14:16:35.890Z" display="block" sm-text-align="left" />
		</Section>
		<Components.Troeven sm-background="--color-primary">
			<Override slot="text7">
				Een digitale werkplek is géén doel op zich.   Het is{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					een middel
				</Strong>
				{" "}om mensen te helpen om hun doelen te bereiken. dimple heeft ruime ervaring met het ontwerp en de bouw van gebruikersvriendelijke digitale webapplicaties binnen een professionele context.
			</Override>
			<Override slot="text5">
				dimple ontwikkelt digitale werkplekken{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					afgestemd op jouw doelen, noden en werkcontext
				</Strong>
				{" "}(en niet omgekeerd!).   Een digitale werkplek is gemaakt om mee te groeien met jouw zaak.  Met elke nieuwe uitdaging en behoefte die zich aandient.{"        "}
			</Override>
		</Components.Troeven>
		<Components.Contact2 />
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2025 dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});