import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Strong, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aanbod/ondersteuning"} />
		<Helmet>
			<title>
				dimple - Ondersteuning
			</title>
			<meta name={"description"} content={"Ook na oplevering blijft dimple beschikbaar voor coaching, training en nazorg.  "} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam>
			<Override slot="menu" exact-active-match={false} />
		</Components.MenuHam>
		<Section padding="20px 0 20px 0" sm-padding="60px 0 60px 0" background="#ffffff">
			<Text margin="0px 0px 0px 0px" font="--headline2" text-align="center">
				Coaching, training en nazorg{"\n\n"}
			</Text>
			<Text margin="0px 0px 0px 0px" />
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 80px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="40%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				/>
				<Image src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/undraw_teaching_re_g7e3.svg?v=2023-05-24T12:38:55.979Z" display="block" max-width="100%" margin="20px 0px 0px 0px" />
				<Box min-width="100px" min-height="100px">
					<Box
						width="100%"
						display="flex"
						flex-direction="row"
						margin="0px 0px 0px 0px"
						lg-margin="0px 4% 15px 0px"
						sm-width="100%"
						sm-margin="0px 0px 30px 0px"
					>
						<Box>
							<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left" />
							<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
								We helpen jou, jouw team of organisatie om het{" "}
								<Strong>
									maximum te halen uit jouw digitale werkplek{" "}
								</Strong>
								door training-op-maat én individuele coaching.  Ook blijven we steeds bereikbaar voor ondersteuning, aanpassingen of uitbreidingen.{"      \n\n"}
							</Text>
						</Box>
					</Box>
				</Box>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				/>
				<Components.TerugNaarAanbod />
				<Link
					href="#"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="#04adb5"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Maak een afspraak
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="60%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Coaching
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Als organisatie, team en individu helpen we jou om jouw{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								noden{" "}
							</Strong>
							en{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								doelen{" "}
							</Strong>
							zo scherp mogelijk te formuleren.  Om{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								hindernissen{" "}
							</Strong>
							te ontwaren én manieren om deze te{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								overwinnen
							</Strong>
							.  Om je uit te dagen, te ondersteunen en te inspireren.  Om problemen creatief op te lossen, te leren uit elke situatie en te groeien.  Elke dag te kijken hoe het beter kan.  Om in te zetten op{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								productiviteit én welzijn
							</Strong>
							, van jou en jouw collega's.  Om te ontdekken hoe jouw digitale werkplek hierbij kan helpen.{"  "}
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Training
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							We dragen gebruikersgemak bij elke ontwerp, hoog in het vaandel.  Door stap-voor-stap te samen te werken, via{" "}
							<Strong>
								hands-on workshops
							</Strong>
							{" "}raak je snel vertrouwd met je nieuwe digitale werkplek.  Waar nodig plannen we een extra training, zodat je deze optimaal kan gebruiken in jouw dagelijks werk.{"   "}
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Nazorg
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							We streven er naar te zorgen dat je na oplevering van jouw digitale werkplek alles weet om ze  dagelijks te gaan gebruiken.{"  "}
							<Strong>
								dimple  blijft bereikbaar
							</Strong>
							{" "}voor vragen, probleempjes, verbeteringsvoorstellen of uitbreidingen.  Omdat we jouw productiviteit én onze relatie van groot belang vinden, doen we er alles aan om jou snel verder te helpen.{"   "}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Troeven>
			<Override slot="text7">
				Een digitale werkplek is géén doel op zich.   Het is{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					een middel
				</Strong>
				{" "}om mensen te helpen om hun doelen te bereiken. dimple heeft ruime ervaring met het ontwerp en de bouw van gebruikersvriendelijke digitale webapplicaties binnen een professionele context.
			</Override>
			<Override slot="text5">
				dimple ontwikkelt digitale werkplekken{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					afgestemd op jouw doelen, noden en werkcontext
				</Strong>
				{" "}(en niet omgekeerd!).   Een digitale werkplek is gemaakt om mee te groeien met jouw zaak.  Met elke nieuwe uitdaging en behoefte die zich aandient.{"        "}
			</Override>
		</Components.Troeven>
		<Components.Contact2 />
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2025 dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});